<script>
  import { page } from '$app/stores';

  // @TODO: Use env vars
  const CANONICAL_ORIGIN = 'https://pointpoker.xyz';

  export let data;
  export let noIndex = false;
</script>

<svelte:head>
  <title>{$page.url.pathname !== '/' ? `${data.title} - Point Poker` : data.title}</title>
  <meta name="description" content={data.description} />
  <link rel="canonical" href={`${CANONICAL_ORIGIN}${$page.url.pathname}`} />

  {#if noIndex}
    <meta name="robots" content="noindex, nofollow" />
  {/if}
</svelte:head>
